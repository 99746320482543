<template>
  <jaya-connection-layout text="Renseignez votre nouveau mot de passe">
    <validation-observer v-slot="{invalid, handleSubmit}">
      <b-form @submit.prevent="handleSubmit(resetPassword)">
        <b-row>
          <!-- Password -->
          <b-col cols="12">
            <p class="font-small-2 font-italic">
              Il doit faire plus de 8 caractères, ne pas ressembler à votre nom, prénom ou adresse mail et être
              composé d'au moins un chiffre, une minuscule, une majuscule et un caractère spécial.
            </p>
            <b-form-group label="Mot de passe et Confirmation">
              <validation-provider v-slot="{errors}" immediate rules="required" vid="password">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon"/>
                  </b-input-group-prepend>
                  <b-form-input v-model="password"
                      :type="passwordFieldType"
                      placeholder="Mot de passe"/>
                </b-input-group>
                <p class="text-danger">{{ errors[0] }}</p>
              </validation-provider>

              <validation-provider v-slot="{errors}" immediate rules="required|confirmed:password">
                <b-input-group class="mt-50">
                  <b-input-group-prepend is-text>
                    <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"/>
                  </b-input-group-prepend>
                  <b-form-input v-model="password_confirm"
                      :type="passwordFieldType"
                      placeholder="Confirmation"/>
                </b-input-group>
                <p class="text-danger">{{ errors[0] }}</p>
                <p v-if="error.password === 'tooShort'"
                    class="text-danger">
                  Votre nouveau mot de passe doit contenir au moins 8 caractères
                  dont une lettre et un chiffre
                </p>
                <p v-if="error.password === 'tooCommonPassword'"
                    class="text-danger">
                  Votre mot de passe est trop simple.
                </p>
                <p v-if="error.password === 'numeric'"
                    class="text-danger">
                  Votre mot de passe ne peut pas être composé exclusivement de chiffres.
                </p>
                <p v-if="error.password === 'tooSimilar'"
                    class="text-danger">
                  Votre mot de passe est trop proche de votre nom d'utilisateur ou email.
                </p>
                <p v-if="error.password === 'badPassword'"
                    class="text-danger">
                  Votre mot de passe ne respecte pas notre politique de sécurité, veuillez en essayer un autre, de
                  plus de 8 caractères dont au moins une lettre et un chiffre
                </p>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Buttons-->
          <b-col class="d-flex justify-content-around flex-wrap mb-1" cols="12">
            <b-button class="m-25 flex-grow-1" to="/login" variant="outline-primary">
              Retour au login
            </b-button>
            <b-button
                :disabled="invalid"
                class="m-25 flex-grow-1"
                type="submit"
                variant="primary">
              Réinitialiser le mot de passe
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </jaya-connection-layout>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from "bootstrap-vue"
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {confirmed, required} from 'vee-validate/dist/rules'
import {togglePasswordVisibility} from "@core/mixins/ui/forms";

extend('required', {...required, message: 'Ce champ est requis'});
extend('confirmed', {...confirmed, message: 'Les mots de passe ne correspondent pas'});

export default {
  components: {
    JayaConnectionLayout,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      password: '',
      password_confirm: '',
      error: {}
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resetPassword() {
      let obj = this.$route.query
      obj.password = this.password
      this.$store.dispatch("account/reset_password", obj)
          .then(() => {
            this.$notify({
              time: 4000,
              title: "Mot de passe",
              text: "Votre mot de passe a bien été changé",
              iconPack: "feather",
              icon: "icon-user-check",
              color: "primary"
            })
            this.$router.push({name: "login"})
          })
          .catch(error => {
            this.$notify({
              time: 4000,
              title: this.$t('Error'),
              text: "Le changement de mot de passe a échoué",
              color: "danger"
            })
            if (error.response) {
              if(error.response.data.detail === "User not found") {
                this.$notify({
                  time: 4000,
                  title: "Inconnu",
                  text: "L'utilisateur est inconnu",
                  color: "danger"
                })
              }
              if(error.response.data.detail === "Invalid signature") {
                this.$notify({
                  time: 4000,
                  title: "Lien",
                  text: "Lien invalide",
                  color: "danger"
                })
              }
              if (Array.isArray(error.response.data.password)) {
                this.error.password = this.$parsePasswordErrors(error.response.data)
              }
            }
          })
    }
  }
}
</script>

<style lang="scss">
</style>
